// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

import './angle/modules/forms/select2';
import $ from 'jquery';

import initKnob from './angle/modules/charts/chart-knob';
import initChartJS from './angle/modules/charts/chart';
import initChartists from './angle/modules/charts/chartist';
import initEasyPieChart from './angle/modules/charts/easypiechart';
import initSellerDatePicker from './angle/modules/forms/forms';
import {
  initFlotSpline,
  initFlotArea,
  initSellerFlotArea,
  initFlotBar,
  initFlotBarStacked,
  initFlotDonut,
  initFlotLine,
  initFlotPie
} from './angle/modules/charts/flot';
import initMorris from './angle/modules/charts/morris';
import initSparkline from './angle/modules/charts/sparkline';
import Cookies from 'js-cookie';


$(document).ready(function () {
  //onload set default locale
  var locale = Cookies.get('locale')
  console.log('locale',locale);
  if(locale == undefined)
  {
    Cookies.set('locale','ca')
  }
  // if ($(".is_collased").attr("value") == "true") {
  //   $("#collapseExample").show();
  //   $(".collapse-button").addClass('collapsed');
  // }
  // Find the links within the list items
  var $countryLinks = $('.locale-option');
  var $sellers = $(document).find("#sellers-from")
  $countryLinks.on('click', function (e) {
    // e.preventDefault(); // Prevent the default link behavior (page refresh/redirect)

    var currentUrl = window.location.href;
    var linkHref = $(this).attr('href');
    var val = $(this).attr('rel'); // Get the "rel" attribute as the locale value
    Cookies.remove('locale')
    Cookies.set('locale', $(this).attr("val"))
    // Get the current query parameters
    var params = new URLSearchParams(window.location.search);
    if (Cookies.get('locale') == 'ph') {
      $sellers.html('Sellers from ph')
    } else if (Cookies.get('locale') == 'ca') {
      $sellers.html('Sellers from ca')
    }
    else {
      $sellers.html('Sellers from us')
    }
    // Update or add the 'locale' parameter based on the clicked link
    params.set('locale', val);
     // Update the URL without refreshing the page
    history.pushState({}, '', `${currentUrl.split('?')[0]}?${params.toString()}`);
  });
  console.log('initiate update product list');
  var curCatValue = $(".home-categories").find(".category-list a .category.active").attr("value");
  var search_text = $(".search-container").find(".search-text").val();
  if(search_text !== undefined)
  {
    updateProductList();
  }
  function updateProductList() {
    var sub_cat_params = "";
    var seller_params = "";
    var ship_params = "";
    var param_list = "?";
    var hasParams = false;

    var params = new URLSearchParams("?");

    var search_text = $(".search-container").find(".search-text").val();

    var cat_params = $(".home-categories").find(".category-list a .category.active").attr("value");

    $(".home-categories").find(".filter-container .sub-categories-filter .item .checkbox input:checked").each(function () {
      sub_cat_params = sub_cat_params != "" ? (sub_cat_params + "%7C" + $(this).attr("value")) : $(this).attr("value");
    });

    $(".home-categories").find(".filter-container .sellers-filter .item .checkbox input:checked").each(function () {
      console.log("mid screen")
      seller_params = seller_params != "" ? (seller_params + "%7C" + $(this).attr("value")) : $(this).attr("value");
    });

    $(".home-categories").find(".filter-container .ship-filter .item .checkbox input:checked").each(function () {
      ship_params = ship_params != "" ? (ship_params + "%7C" + $(this).attr("value")) : $(this).attr("value");
    });


    $(".home-categories").find(".filter-container-mobile .sub-categories-filter .item .checkbox input:checked").each(function () {
      sub_cat_params = sub_cat_params != "" ? (sub_cat_params + "," + $(this).attr("value")) : $(this).attr("value");
    });

    $(".home-categories").find(".filter-container-mobile .sellers-filter .item .checkbox input:checked").each(function () {
      console.log("mobile")
      seller_params = seller_params != "" ? (seller_params + "," + $(this).attr("value")) : $(this).attr("value");
    });

    $(".home-categories").find(".filter-container-mobile .ship-filter .item .checkbox input:checked").each(function () {
      ship_params = ship_params != "" ? (ship_params + "," + $(this).attr("value")) : $(this).attr("value");
    });

    var new_url = window.location.origin + window.location.pathname + "?";

    if (search_text !== "" && search_text !== undefined) {
      params.append("search", search_text);
      console.log('appended',search_text)
      // param_list = param_list + "search="+search_text+"&";
      // new_url = new_url + "search="+search_text+"&";
    }
    if (cat_params != undefined) {
      params.append("c", cat_params);
      // param_list = param_list + "c="+cat_params+"&";
      // new_url = new_url + "c="+cat_params+"&";
    }
    if (sub_cat_params != "") {
      params.append("sc", sub_cat_params);
      // param_list = param_list + "sc="+sub_cat_params+"&";
      // new_url = new_url + "sc="+sub_cat_params+"&";
    }
    if (seller_params != "") {
      params.append("s", seller_params);
      // param_list = param_list + "s="+seller_params+"&";
      // new_url = new_url + "s="+seller_params+"&";
    }
    if (ship_params != "") {
      params.append("sp", ship_params);
      // param_list = param_list + "sp="+ship_params+"&";
      // new_url = new_url + "sp="+ship_params+"&";
    }

    new_url = new_url + params.toString();
    window.history.pushState("data", "Title", new_url);
    document.title = new_url;

    $(".home-categories").find(".products").html("");
    $(".home-categories").find(".loading-gif").removeClass("hide");
    $.ajax({
      type: "GET",
      url: "/categories/product_filter?" + params.toString(),
      success: function (result) {
        $(".home-categories").find(".loading-gif").addClass("hide");
        $(".home-categories").find(".products").html("");
        if (result.status == 200) {
          $(".home-categories").find(".num-products").html(result.products.length);
          $(".home-categories").find(".search-container .search-value").html(result.products.length + ' Items with "' + search_text + '"')
          $.each(result.products, function (index, prod) {
            var $prod = $(".home-categories").find("a.clone").clone();
            $prod.attr("href", "/products/" + prod.id);
            $prod.find(".name").html(prod.name);
            $prod.find(".price").html("&#x20B1; " + prod.price);
            $prod.find(".image").attr("style", "background-image: url(" + prod.image_url + ")");
            $prod.removeClass("clone hide");
            $prod.appendTo($(".home-categories").find(".products"));
          });

          if (curCatValue != cat_params) {
            curCatValue = cat_params;
            $(".home-categories").find(".sub-categories-filter, .sellers-filter, .ship-filter").html("");
            $.each(result.sub_categories, function (index, sub_cat) {
              addFilter($(".home-categories").find(".sub-categories-filter"), "category", sub_cat.category, sub_cat.cat_value);
            });
            $.each(result.stores, function (index, store) {
              addFilter($(".home-categories").find(".sellers-filter"), "store", store.name, store.id, store.count);
            });
            $.each(result.origins, function (index, origin) {
              addFilter($(".home-categories").find(".ship-filter"), "ship", origin.name, origin.name, origin.count);
            });
          }
        }
        else {
          console.log(result.error)
        }
      }
    });
  }
});

$(function () {
  initKnob();
  initChartJS();
  initChartists();
  initEasyPieChart();
  initMorris();
  initSparkline();
  // Flot charts
  initFlotSpline();
  // initFlotArea();
  // initSellerFlotArea();
  initFlotBar();
  initFlotBarStacked();
  initFlotDonut();
  initFlotLine();
  initFlotPie();
  initSellerDatePicker();

  $("#sort_button").on("click", function () {
    if ($("#smol-filter").hasClass("filter-active")) {
      $("#smol-filter").removeClass("filter-active")
      $("#smol-filter").css("width", "0%")
      $("#smol-filter").css("padding", "0px")
    } else {
      $("#smol-filter").addClass("filter-active")
      $("#smol-filter").css("width", "90%")
      $("#smol-filter").css("padding", "20px")
    }
  });


  if ($(".main-dashboard").length) {

    var $lists = $('.countrypicker');

    $(document).on("click", ".countrypicker", function (e) {
      console.log("here");
      // e.stopPropagation();
      // var $tgt = $(e.target);
      // $(this).find('ul').slideToggle('fast');
    });

    $(document).on("click", ".countrypicker ul li:not(.disabled)", function (e) {
      $lists.find(".span .flag").addClass("hide");
      $lists.find(".span .flag." + $(this).attr("val")).removeClass("hide");
      Cookies.remove('locale')
      Cookies.set('locale', $(this).attr("val"))
      location.reload()
    });

    $(document).click(function (e) {
      $lists.find('ul:visible').hide();
    });

    $('[data-ui-slider]').slider();

    $(".aside-container, .offsidebar").remove();
    $(".section-container").css({
      "height": $(document).height()
    });
    $(".section-container").addClass("section-main");

    $(".search-container").on("keypress", ".search-text", function (e) {
      var search_text = $(this).val();
      if (e.key == "Enter") {
        if ($(".home-categories").length) {
          updateProductList();
        }
        else {
          if (search_text != "")
            window.location.href = "?search=" + search_text;
        }
      }
    });
    $(".search-container").on("click", ".search-button", function () {
      var search_text = $(".search-container").find(".search-text").val();
      if ($(".home-categories").length) {
        updateProductList();
      }
      else {
        if (search_text != "")
          window.location.href = "?search=" + search_text;
      }
    });
    $(".search-container-mobile").on("click", ".search-button", function () {
      var search_text = $(".search-container-mobile").find(".search-text").val();
      if ($(".home-categories").length) {
        updateProductList();
      }
      else {
        if (search_text != "")
          window.location.href = "?search=" + search_text;
      }
    });
    $(".home-categories").on("click", ".category-list a", function (e) {
      e.preventDefault();
      if ($(this).find(".category").hasClass("active")) {
        $(this).find(".category").removeClass("active");
      }
      else {
        $(".home-categories").find(".category-list a .category.active").removeClass("active");
        $(this).find(".category").addClass("active");
      }
      updateProductList();
    });

    $(".home-categories").on("change", ".filter-container .item .checkbox", function () {
      updateProductList();
    });

    $(".home-categories").on("change", ".filter-container-mobile .item .checkbox", function () {
      updateProductList();
    });

    var curCatValue = $(".home-categories").find(".category-list a .category.active").attr("value");
    function updateProductList() {
      var sub_cat_params = "";
      var seller_params = "";
      var ship_params = "";
      var param_list = "?";
      var hasParams = false;

      var params = new URLSearchParams("?");

      var search_text = $(".search-container").find(".search-text").val();

      var cat_params = $(".home-categories").find(".category-list a .category.active").attr("value");

      $(".home-categories").find(".filter-container .sub-categories-filter .item .checkbox input:checked").each(function () {
        sub_cat_params = sub_cat_params != "" ? (sub_cat_params + "%7C" + $(this).attr("value")) : $(this).attr("value");
      });

      $(".home-categories").find(".filter-container .sellers-filter .item .checkbox input:checked").each(function () {
        console.log("mid screen")
        seller_params = seller_params != "" ? (seller_params + "%7C" + $(this).attr("value")) : $(this).attr("value");
      });

      $(".home-categories").find(".filter-container .ship-filter .item .checkbox input:checked").each(function () {
        ship_params = ship_params != "" ? (ship_params + "%7C" + $(this).attr("value")) : $(this).attr("value");
      });


      $(".home-categories").find(".filter-container-mobile .sub-categories-filter .item .checkbox input:checked").each(function () {
        sub_cat_params = sub_cat_params != "" ? (sub_cat_params + "," + $(this).attr("value")) : $(this).attr("value");
      });

      $(".home-categories").find(".filter-container-mobile .sellers-filter .item .checkbox input:checked").each(function () {
        console.log("mobile")
        seller_params = seller_params != "" ? (seller_params + "," + $(this).attr("value")) : $(this).attr("value");
      });

      $(".home-categories").find(".filter-container-mobile .ship-filter .item .checkbox input:checked").each(function () {
        ship_params = ship_params != "" ? (ship_params + "," + $(this).attr("value")) : $(this).attr("value");
      });

      var new_url = window.location.origin + window.location.pathname + "?";

      if (search_text != "") {
        params.append("search", search_text);
        // param_list = param_list + "search="+search_text+"&";
        // new_url = new_url + "search="+search_text+"&";
      }
      if (cat_params != undefined) {
        params.append("c", cat_params);
        // param_list = param_list + "c="+cat_params+"&";
        // new_url = new_url + "c="+cat_params+"&";
      }
      if (sub_cat_params != "") {
        params.append("sc", sub_cat_params);
        // param_list = param_list + "sc="+sub_cat_params+"&";
        // new_url = new_url + "sc="+sub_cat_params+"&";
      }
      if (seller_params != "") {
        params.append("s", seller_params);
        // param_list = param_list + "s="+seller_params+"&";
        // new_url = new_url + "s="+seller_params+"&";
      }
      if (ship_params != "") {
        params.append("sp", ship_params);
        // param_list = param_list + "sp="+ship_params+"&";
        // new_url = new_url + "sp="+ship_params+"&";
      }

      new_url = new_url + params.toString();
      window.history.pushState("data", "Title", new_url);
      document.title = new_url;

      $(".home-categories").find(".products").html("");
      $(".home-categories").find(".loading-gif").removeClass("hide");
      $.ajax({
        type: "GET",
        url: "/categories/product_filter?" + params.toString(),
        success: function (result) {
          $(".home-categories").find(".loading-gif").addClass("hide");
          $(".home-categories").find(".products").html("");
          if (result.status == 200) {
            $(".home-categories").find(".num-products").html(result.products.length);
            $(".home-categories").find(".search-container .search-value").html(result.products.length + ' Items with "' + search_text + '"')
            $.each(result.products, function (index, prod) {
              var $prod = $(".home-categories").find("a.clone").clone();
              $prod.attr("href", "/products/" + prod.id);
              $prod.find(".name").html(prod.name);
              $prod.find(".price").html("&#x20B1; " + prod.price);
              $prod.find(".image").attr("style", "background-image: url(" + prod.image_url + ")");
              $prod.removeClass("clone hide");
              $prod.appendTo($(".home-categories").find(".products"));
            });

            if (curCatValue != cat_params) {
              curCatValue = cat_params;
              $(".home-categories").find(".sub-categories-filter, .sellers-filter, .ship-filter").html("");
              $.each(result.sub_categories, function (index, sub_cat) {
                addFilter($(".home-categories").find(".sub-categories-filter"), "category", sub_cat.category, sub_cat.cat_value);
              });
              $.each(result.stores, function (index, store) {
                addFilter($(".home-categories").find(".sellers-filter"), "store", store.name, store.id, store.count);
              });
              $.each(result.origins, function (index, origin) {
                addFilter($(".home-categories").find(".ship-filter"), "ship", origin.name, origin.name, origin.count);
              });
            }
          }
          else {
            console.log(result.error)
          }
        }
      });
    }

    function addFilter(parent, type, name, value, count = null) {
      var $item = $(".home-categories").find(".filter-container .item.clone").clone();
      $item.removeClass("clone hide");
      if (type != "category") {
        $item.addClass("ship");
        $item.find("label.col-form-label span.num").html(count);
      }
      $item.find("input").attr("value", value);
      $item.find("label.col-form-label span.name").html(name);

      $item.appendTo(parent);
    }
  }

  if ($(".small-screen").length) {

    var $lists = $('.countrypicker');

    $(document).on("click", ".countrypicker", function (e) {
      e.stopPropagation();
      var $tgt = $(e.target);
      $(this).find('ul').slideToggle('fast');
    });

    $(document).on("click", ".countrypicker ul li:not(.disabled)", function (e) {
      $lists.find(".span .flag").addClass("hide");
      $lists.find(".span .flag." + $(this).attr("val")).removeClass("hide");
    });

    $(document).click(function (e) {
      $lists.find('ul:visible').hide();
    });

    $('[data-ui-slider]').slider();

    $(".aside-container, .offsidebar").remove();
    $(".section-container").css({
      "height": $(document).height()
    });
    $(".section-container").addClass("section-main");

    $(".search-container").on("keypress", ".search-text", function (e) {
      var search_text = $(this).val();
      if (e.key == "Enter") {
        if ($(".home-categories").length) {
          updateProductList();
        }
        else {
          if (search_text != "")
            window.location.href = "?search=" + search_text;
        }
      }
    });
    $(".search-container").on("click", ".search-button", function () {
      var search_text = $(".search-container").find(".search-text").val();
      if ($(".home-categories").length) {
        updateProductList();
      }
      else {
        if (search_text != "")
          window.location.href = "?search=" + search_text;
      }
    });
    $(".search-container-mobile").on("click", ".search-button", function () {
      var search_text = $(".search-container-mobile").find(".search-text").val();
      if ($(".home-categories").length) {
        updateProductList();
      }
      else {
        if (search_text != "")
          window.location.href = "?search=" + search_text;
      }
    });
    $(".home-categories").on("click", ".category-list a", function (e) {
      e.preventDefault();
      if ($(this).find(".category").hasClass("active")) {
        $(this).find(".category").removeClass("active");
      }
      else {
        $(".home-categories").find(".category-list a .category.active").removeClass("active");
        $(this).find(".category").addClass("active");
      }
      updateProductList();
    });

    $(".home-categories").on("change", ".filter-container .item .checkbox", function () {
      updateProductList();
    });

    var curCatValue = $(".home-categories").find(".category-list a .category.active").attr("value");
    function updateProductList() {
      var sub_cat_params = "";
      var seller_params = "";
      var ship_params = "";
      var param_list = "?";
      var hasParams = false;

      var params = new URLSearchParams("?");

      var search_text = $(".search-container").find(".search-text").val();

      var cat_params = $(".home-categories").find(".category-list a .category.active").attr("value");

      $(".home-categories").find(".filter-container .sub-categories-filter .item .checkbox input:checked").each(function () {
        sub_cat_params = sub_cat_params != "" ? (sub_cat_params + "%7C" + $(this).attr("value")) : $(this).attr("value");
      });

      $(".home-categories").find(".filter-container .sellers-filter .item .checkbox input:checked").each(function () {
        seller_params = seller_params != "" ? (seller_params + "%7C" + $(this).attr("value")) : $(this).attr("value");
      });

      $(".home-categories").find(".filter-container .ship-filter .item .checkbox input:checked").each(function () {
        ship_params = ship_params != "" ? (ship_params + "%7C" + $(this).attr("value")) : $(this).attr("value");
      });
      
      var new_url = window.location.origin + window.location.pathname + "?";

      if (search_text !== "" && search_text !== undefined) {
        params.append("search", search_text);
        console.log('appended',search_text)
        // param_list = param_list + "search="+search_text+"&";
        // new_url = new_url + "search="+search_text+"&";
      }
      if (cat_params != undefined) {
        params.append("c", cat_params);
        // param_list = param_list + "c="+cat_params+"&";
        // new_url = new_url + "c="+cat_params+"&";
      }
      if (sub_cat_params != "") {
        params.append("sc", sub_cat_params);
        // param_list = param_list + "sc="+sub_cat_params+"&";
        // new_url = new_url + "sc="+sub_cat_params+"&";
      }
      if (seller_params != "") {
        params.append("s", seller_params);
        // param_list = param_list + "s="+seller_params+"&";
        // new_url = new_url + "s="+seller_params+"&";
      }
      if (ship_params != "") {
        params.append("sp", ship_params);
        // param_list = param_list + "sp="+ship_params+"&";
        // new_url = new_url + "sp="+ship_params+"&";
      }

      new_url = new_url + params.toString();
      window.history.pushState("data", "Title", new_url);
      document.title = new_url;

      $(".home-categories").find(".products").html("");
      $(".home-categories").find(".loading-gif").removeClass("hide");
      $.ajax({
        type: "GET",
        url: "/categories/product_filter?" + params.toString(),
        success: function (result) {
          $(".home-categories").find(".loading-gif").addClass("hide");
          $(".home-categories").find(".products").html("");
          if (result.status == 200) {
            $(".home-categories").find(".num-products").html(result.products.length);
            $(".home-categories").find(".search-container .search-value").html(result.products.length + ' Items with "' + search_text + '"')
            $.each(result.products, function (index, prod) {
              var $prod = $(".home-categories").find("a.clone").clone();
              $prod.attr("href", "/products/" + prod.id);
              $prod.find(".name").html(prod.name);
              $prod.find(".price").html("&#x20B1; " + prod.price);
              $prod.find(".image").attr("style", "background-image: url(" + prod.image_url + ")");
              $prod.removeClass("clone hide");
              $prod.appendTo($(".home-categories").find(".products"));
            });

            if (curCatValue != cat_params) {
              curCatValue = cat_params;
              $(".home-categories").find(".sub-categories-filter, .sellers-filter, .ship-filter").html("");
              $.each(result.sub_categories, function (index, sub_cat) {
                addFilter($(".home-categories").find(".sub-categories-filter"), "category", sub_cat.category, sub_cat.cat_value);
              });
              $.each(result.stores, function (index, store) {
                addFilter($(".home-categories").find(".sellers-filter"), "store", store.name, store.id, store.count);
              });
              $.each(result.origins, function (index, origin) {
                addFilter($(".home-categories").find(".ship-filter"), "ship", origin.name, origin.name, origin.count);
              });
            }
          }
          else {
            console.log(result.error)
          }
        }
      });
    }

    function addFilter(parent, type, name, value, count = null) {
      var $item = $(".home-categories").find(".filter-container .item.clone").clone();
      $item.removeClass("clone hide");
      if (type != "category") {
        $item.addClass("ship");
        $item.find("label.col-form-label span.num").html(count);
      }
      $item.find("input").attr("value", value);
      $item.find("label.col-form-label span.name").html(name);

      $item.appendTo(parent);
    }
  }

})
$(document).ready(function () {
  if ($(".admin-dashboard").length) {

    var options = {
      series: {
        lines: {
          show: true,
          fill: 0.01
        },
        points: {
          show: true,
          radius: 4
        }
      },
      grid: {
        borderColor: '#eee',
        borderWidth: 1,
        hoverable: true,
        backgroundColor: '#fcfcfc'
      },
      tooltip: true,
      tooltipOpts: {
        content: function (label, x, y) { return x + ' : ' + y; }
      },
      xaxis: {
        tickColor: '#eee',
        mode: 'categories'
      },
      yaxis: {
        // position: 'right' or 'left'
        tickDecimals: 0,
        tickColor: '#eee'
      },
      shadowSize: 0
    };

    var chart = $(".chart-line");
    if (chart.length) {
      $.ajax({
        type: "GET",
        url: "api/v1/orders",
        data: { "type": "admin-dashboard" },
        success: function (result) {
          // console.log("SUCCESS!!", data)
          var completed = [];
          var inProgress = [];
          var cancelled = [];
          $.each(result.completed, function (index, val) {
            completed.push([val.month, val.orders]);
          });
          $.each(result.in_progress, function (index, val) {
            inProgress.push([val.month, val.orders]);
          });
          $.each(result.cancelled, function (index, val) {
            cancelled.push([val.month, val.orders]);
          });
          // completed << ["Jan", result.completed[0].orders];
          var data = [{
            "label": "Complete",
            "color": "#5ab1ef",
            "data": completed
          }, {
            "label": "In Progress",
            "color": "#f5994e",
            "data": inProgress
          }, {
            "label": "Cancelled",
            "color": "#d87a80",
            "data": cancelled
          }];
          jQuery.plot(chart, data, options);
        },
        error: function () {
          console.log("FAILURE");
        }
      });
    }
  }

  if ($(".seller-dashboard").length) {
    // initSellerFlotArea();
    var isChartDisplay = "year";

    var chart = $('.chart-area');
    var icons = {
      time: 'fa fa-clock-o',
      date: 'fa fa-calendar',
      up: 'fa fa-chevron-up',
      down: 'fa fa-chevron-down',
      previous: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      today: 'fa fa-crosshairs',
      clear: 'fa fa-trash'
    };
    $('#datetimepickerYear').datepicker({
      orientation: 'bottom',
      autoclose: true,
      yearRange: "-100:+0",
      dateFormat: 'yy',
      viewMode: "years",
      minViewMode: "years",
      icons: icons
    }).datepicker("setDate", 'now');

    $('#datetimepickerMonth').datepicker({
      orientation: 'bottom',
      autoclose: true,
      yearRange: "-100:+0",
      dateFormat: 'mm/yy',
      viewMode: "months",
      minViewMode: "months",
      icons: icons
    }).datepicker("setDate", 'now');

    $('#datetimepickerDaily').datepicker({
      orientation: 'bottom',
      autoclose: true,
      yearRange: "-100:+0",
      dateFormat: 'dd/mm/yy',
      viewMode: "days",
      minViewMode: "days",
      icons: icons
    }).datepicker("setDate", 'now');

    var options = {
      series: {
        lines: {
          show: true,
          fill: 0.8
        },
        points: {
          show: true,
          radius: 4
        }
      },
      grid: {
        borderColor: '#eee',
        borderWidth: 1,
        hoverable: true,
        backgroundColor: '#fcfcfc'
      },
      tooltip: true,
      tooltipOpts: {
        content: function (label, x, y) { return x + ' : ' + y; }
      },
      xaxis: {
        tickColor: '#fcfcfc',
        mode: 'categories'
      },
      yaxis: {
        min: 0,
        tickColor: '#eee',
        tickDecimals: 0,
        // position: 'right' or 'left'
        tickFormatter: function (v) {
          // return v + ' visitors';
          return v;
        }
      },
      shadowSize: 0
    };

    var isDateChange = true;
    var $year = 0;
    var $month = 0;
    $('#datetimepickerYear').datepicker().on('changeDate', function (ev) {
      var from = $("#datetimepickerYear input").val().split("/");
      $year = from[2];
      fetchChartData();
    });

    $('#datetimepickerMonth').datepicker().on('changeDate', function (ev) {
      var from = $("#datetimepickerMonth input").val().split("/");
      $year = from[2];
      $month = from[0];
      fetchChartData();
    });

    $('#datetimepickerDaily').datepicker().on('changeDate', function (ev) {
      var from = $("#datetimepickerDaily input").val().split("/");
      $year = from[2];
      $month = from[0];
      fetchChartData();
    });

    $(".seller-dashboard").on("click", ".btn-year", function () {
      isChartDisplay = "year";
      $(".btn-day, .btn-week").removeClass("btn-primary");
      $(this).addClass("btn-primary")
      $('#datetimepickerMonth, #datetimepickerDaily').addClass("hide");
      $('#datetimepickerYear').removeClass("hide");
      var from = $("#datetimepickerYear input").val().split("/");
      $year = from[2];
      fetchChartData();
    });

    $(".seller-dashboard").on("click", ".btn-week", function () {
      isChartDisplay = "week";
      $(".btn-day, .btn-year").removeClass("btn-primary");
      $(this).addClass("btn-primary")
      $('#datetimepickerYear, #datetimepickerDaily').addClass("hide");
      $('#datetimepickerMonth').removeClass("hide");
      var from = $("#datetimepickerMonth input").val().split("/");
      $year = from[2];
      $month = from[0];
      fetchChartData();
    });

    $(".seller-dashboard").on("click", ".btn-day", function () {
      isChartDisplay = "day";
      $(".btn-year, .btn-week").removeClass("btn-primary");
      $(this).addClass("btn-primary")
      $('#datetimepickerYear, #datetimepickerMonth').addClass("hide");
      $('#datetimepickerDaily').removeClass("hide");
      var from = $("#datetimepickerDaily input").val().split("/");
      $year = from[2];
      $month = from[0];
      fetchChartData();
    });

    fetchChartData();

    function fetchChartData() {
      var $data = {
        type: isChartDisplay,
        year: $year,
        month: $month
      }

      $.ajax({
        type: "GET",
        url: "api/v1/orders",
        data: { "type": "seller-dashboard", data: $data },
        success: function (result) {
          // console.log("SUCCESS!!", data)
          var completed = [];
          var income = [];
          $.each(result.completed, function (index, val) {
            completed.push([val.text, val.orders]);
          });
          $.each(result.income_arr, function (index, val) {
            income.push([val.text, "C$ " + val.income]);
          });
          // completed << ["Jan", result.completed[0].orders];
          var data = [{
            // "label": "Income",
            "color": "#228B22",
            "data": income
          }, {
            // "label": "Complete",
            "color": "#5d9cec",
            "data": completed
          }];
          jQuery.plot(chart, data, options);
        },
        error: function () {
          console.log("FAILURE");
        }
      });
    }
  }
  if ($(".storefront").length) {
    let $homepage = $(".storefront");
    $(".sort-select, .category-select").select2({
      theme: 'bootstrap4'
    });

    let timeoutSlider = setTimeout(function () {
      // filterProducts();
    }, 500);

    $homepage.on("click", ".tabs-container .tab:not(.dropdown, .active)", function () {
      $homepage.find(".category-select").val("all").trigger("change");
      $homepage.find(".tabs-container .tab.active").removeClass("active");
      $(this).addClass("active");
      // filterProducts();
    });

    $homepage.on("change", ".sort-select", function () {
      filterProducts();
    });

    $homepage.on("change", ".category-select", function () {
      $homepage.find(".tabs-container .tab.active").removeClass("active");
      $homepage.find(".tabs-container .tab.dropdown").addClass("active");
      filterProducts();
    });

    $homepage.on("keyup", ".search-container input", function (e) {
      clearTimeout(timeoutSlider);

      if (e.keyCode == 13) {
        // timeoutSlider = setTimeout(function(){
        //   filterProducts();
        // }, 500);
        filterProducts();
      }
    });

    let filterProducts = function () {
      let url = window.location.pathname + "/storefront_products";

      url = url + "?order=" + $homepage.find(".sort-select").val();

      if ($homepage.find(".search-container input").val().trim() != "") {
        url += "&search=" + $homepage.find(".search-container input").val();
      }

      if ($homepage.find(".category-select").val() != "All") {
        url += "&category=" + $homepage.find(".category-select").val();
      }
      $homepage.find(".whirl.no-overlay").removeClass("hide");
      $homepage.find(".products .items-container").html("");
      $.ajax({
        type: "GET",
        url: url,
        success: function (result) {
          $homepage.find(".whirl.no-overlay").addClass("hide");
          if (result.status == 200) {
            $.each(result["products"], function (ind, prod) {
              let $product = $homepage.find(".products a.clone").clone();
              $product.removeClass("clone hide");
              $product.find(".name").html(prod["name"]);
              $product.find(".price").html(prod["currency"] + " " + prod["price"]);
              $product.find(".image").attr("style", "background-image: url(" + prod["image_url"] + ")");
              $product.attr("href", "/products/" + prod["id"]);
              $product.appendTo($homepage.find(".products .items-container"));
            });
          }
        }
      });
    }
  }
})