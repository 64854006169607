// Chart JS
// -----------------------------------

// Chart.js
import Chart from 'chart.js';

function initChartJS() {

    if (typeof Chart === 'undefined') return;

    if (!document.getElementById('chartjs-linechart') ||
        !document.getElementById('chartjs-barchart') ||
        !document.getElementById('chartjs-doughnutchart') ||
        !document.getElementById('chartjs-piechart') ||
        !document.getElementById('chartjs-polarchart') ||
        !document.getElementById('chartjs-radarchart'))
        return

    // random values for demo
    var rFactor = function() {
        return Math.round(Math.random() * 100);
    };

    // Line chart
    // -----------------------------------

    var lineData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: 'My First dataset',
            backgroundColor: 'rgba(114,102,186,0.2)',
            borderColor: 'rgba(114,102,186,1)',
            pointBorderColor: '#fff',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }, {
            label: 'My Second dataset',
            backgroundColor: 'rgba(35,183,229,0.2)',
            borderColor: 'rgba(35,183,229,1)',
            pointBorderColor: '#fff',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }]
    };

    var lineOptions = {
        legend: {
            display: false
        }
    };
    var linectx = document.getElementById('chartjs-linechart').getContext('2d');
    var lineChart = new Chart(linectx, {
        data: lineData,
        type: 'line',
        options: lineOptions
    });

    // Bar chart
    // -----------------------------------

    var barData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            backgroundColor: '#23b7e5',
            borderColor: '#23b7e5',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }, {
            backgroundColor: '#5d9cec',
            borderColor: '#5d9cec',
            data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
        }]
    };

    var barOptions = {
        legend: {
            display: false
        }
    };
    var barctx = document.getElementById('chartjs-barchart').getContext('2d');
    var barChart = new Chart(barctx, {
        data: barData,
        type: 'bar',
        options: barOptions
    });

    //  Doughnut chart
    // -----------------------------------

    var doughnutData = {
        labels: [
            'Purple',
            'Yellow',
            'Blue'
        ],
        datasets: [{
            data: [300, 50, 100],
            backgroundColor: [
                '#7266ba',
                '#fad732',
                '#23b7e5'
            ],
            hoverBackgroundColor: [
                '#7266ba',
                '#fad732',
                '#23b7e5'
            ]
        }]
    };

    var doughnutOptions = {
        legend: {
            display: false
        }
    };
    var doughnutctx = document.getElementById('chartjs-doughnutchart').getContext('2d');
    var doughnutChart = new Chart(doughnutctx, {
        data: doughnutData,
        type: 'doughnut',
        options: doughnutOptions
    });

    // Pie chart
    // -----------------------------------

    var pieData = {
        labels: [
            'Purple',
            'Yellow',
            'Blue'
        ],
        datasets: [{
            data: [300, 50, 100],
            backgroundColor: [
                '#7266ba',
                '#fad732',
                '#23b7e5'
            ],
            hoverBackgroundColor: [
                '#7266ba',
                '#fad732',
                '#23b7e5'
            ]
        }]
    };

    var pieOptions = {
        legend: {
            display: false
        }
    };
    var piectx = document.getElementById('chartjs-piechart').getContext('2d');
    var pieChart = new Chart(piectx, {
        data: pieData,
        type: 'pie',
        options: pieOptions
    });

    // Polar chart
    // -----------------------------------

    var polarData = {
        datasets: [{
            data: [
                11,
                16,
                7,
                3
            ],
            backgroundColor: [
                '#f532e5',
                '#7266ba',
                '#f532e5',
                '#7266ba'
            ],
            label: 'My dataset' // for legend
        }],
        labels: [
            'Label 1',
            'Label 2',
            'Label 3',
            'Label 4'
        ]
    };

    var polarOptions = {
        legend: {
            display: false
        }
    };
    var polarctx = document.getElementById('chartjs-polarchart').getContext('2d');
    var polarChart = new Chart(polarctx, {
        data: polarData,
        type: 'polarArea',
        options: polarOptions
    });

    // Radar chart
    // -----------------------------------

    var radarData = {
        labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
        datasets: [{
            label: 'My First dataset',
            backgroundColor: 'rgba(114,102,186,0.2)',
            borderColor: 'rgba(114,102,186,1)',
            data: [65, 59, 90, 81, 56, 55, 40]
        }, {
            label: 'My Second dataset',
            backgroundColor: 'rgba(151,187,205,0.2)',
            borderColor: 'rgba(151,187,205,1)',
            data: [28, 48, 40, 19, 96, 27, 100]
        }]
    };

    var radarOptions = {
        legend: {
            display: false
        }
    };
    var radarctx = document.getElementById('chartjs-radarchart').getContext('2d');
    var radarChart = new Chart(radarctx, {
        data: radarData,
        type: 'radar',
        options: radarOptions
    });

}

export default initChartJS;